import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import SmartButton from '@/components/smart-button/smart-button';
import SmartSearchTextBox from '@/components/smart-textbox/smart-search-textbox';
import HeaderFooterContext from '@/context/HeaderFooterContext';

import styles from './right.module.scss';











const MyAccountModal = dynamic(() => import('./my-account-modal'));

const RightPanelMenu = () => {
  const [showModal, setShowModal] = useState(false);

  
  const [isCareersPage, setIsCareersPage] = useState(false);

  const router = useRouter();
  const { locale }: any = router;

  const headerFooterData = useContext(HeaderFooterContext);
  const { headerFooter } = headerFooterData;
  const easternTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const hour = new Date(easternTime).getHours();

  const contactNumber = headerFooter[0]?.attributes?.RC_Contact_Number;
  const timings = headerFooter[0]?.attributes?.Timings;
  let today = new Date().getDay();
  if (today === 0) today = 6;
  else today -= 1;

  const startTime = timings && timings[today].Start_Time;
  const endTime = timings && timings[today].End_Time;
  const openHours = `${startTime} ${locale === 'en' ? 'to' : 'à'} ${endTime} ${
    locale === 'en' ? 'ET' : ', HE'
  }`;

  const isOpen = +hour >= 8 && +hour <= 21;

  useEffect(() => {
    setIsCareersPage(
      router.asPath.includes('careers') || router.asPath.includes('carrieres')
    );
  }, [router]);

  const ContactInfo = () => {
    if (isOpen) {
      return (
        <div>
          <FormattedMessage id="header.weAreOpen" />
        </div>
      );
    }
    return (
      <div>
        {startTime} {locale === 'en' ? ' to' : 'à'} {endTime}{' '}
        {locale === 'en' ? 'ET' : ', HE'}
      </div>
    );
  };

  const contactStyle = () => {
    if (hour >= 8 && hour <= 21) {
      return styles.call;
    }
    return `${styles.call} ${styles.call_gray}`;
  };
  return (
    <div>
      <div className="right-menu">
        <ul className="navbar-nav flex-row justify-content-end">
          <li className={styles.li}>
            <SmartSearchTextBox></SmartSearchTextBox>
          </li>
          <li className={`${styles.li} ${styles['my-account-btn']}`}>
            <SmartButton
              onClick={() => setShowModal(true)}
              text="header.myaccount-makepayment"
              textClassName={styles['my-account']}
              iconProps={{
                style: {
                  fontSize: '28px',
                  maxWidth: '28px',
                  maxHeight: '28px',
                },
              }}
              hasIcon
              Icon={AccountCircleOutlined}
            />
          </li>
          {showModal && (
            <MyAccountModal
              open={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
          {/* Hidden in Access */}
          {/* <li className={`${styles.li} ${styles['sv-language-btn']}`}>
            <SmartButton
              href={locale === 'en' ? `/fr${asPath}` : `/en${asPath}`}
              text={locale === 'en' ? 'FR' : 'EN'}
            />
          </li> */}
          {!isCareersPage && (
            <li className={`${styles.li} nav-item`}>
              <div className={styles['sv-header-phone']}>
                <div className={contactStyle()}>
                  <LocalPhoneOutlinedIcon />
                  <ContactInfo />
                </div>
                <div>
                  <a
                    className={`${styles['sv-contact-number']} nav-link pt-0 ps-0 text-center headerPhone`}
                    href={`tel:${contactNumber}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={openHours}
                  >
                    {contactNumber}
                  </a>
                </div>
              </div>
              <div className={styles['sv-header-phone-btn']}>
                <SmartButton
                  href={`tel:${contactNumber}`}
                  hasIcon
                  Icon={LocalPhoneOutlinedIcon}
                  text=""
                  dot={isOpen}
                />
              </div>
            </li>
          )}
          {isCareersPage && (
            <li className={`${styles.li} nav-item`}>
              <div
                className={
                  'd-flex align-items-center h-100 se-primary-header-btn'
                }
              >
                <a
                  href={'https://careers.storagevaultcanada.com/'}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className="btn se-primary-btn se-primary-header-btn "
                  >
                    <FormattedMessage id="careers-header-apply" />
                  </button>
                </a>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RightPanelMenu;
