import CropIcon from '@mui/icons-material/Crop';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import $ from "jquery";
import Image from "next/future/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import SizeCardModal from "@/components/self-storage-moving/size-card";
import locationPin from "@/public/images/icons/location-pin.svg";
import { PACKING_SUPPLIES_SHOP } from "@/utils/constants";

import MyAccountModal from "../right-panel-menu/my-account-modal";
import styles from "./mobile.module.scss";

const MobileHeader = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);
  const { locales, asPath, locale }: any = useRouter();
  const router = useRouter();

  const storageDropdownRef: any = useRef();
  useEffect(() => {
    $(() => {
      $(".close-menu").on("click", () => {
        if ($(".navbar-toggler").css("display") !== "none") {
          $(".navbar-toggler").trigger("click");
        }
      });
    });
  });

  return (
    <>
    <nav className="">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={props.handleExpand}
      >
        <div id="nav-icon3">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div
        id="overlay-for-menu"
        style={props.showClass}
        onClick={props.handleExpand}
      ></div>
      <div
        className="navbar-collapse sidenav"
        id="mobileNav"
        style={props.heightStyle}
      >
        <div className="overlay-content">
          <ul className="navbar-nav">
            <li className="nav-item pt-4">
              <a
                className="nav-link close-menu d-flex"
                onClick={() => setSizeModal(true)}
              >
                <div>
                  <CropIcon className="sv-size-guid-icon" />
                </div>
                <FormattedMessage id="header.size-guide" />
              </a>
            </li>
            {sizeModal && (
              <SizeCardModal
                open={sizeModal}
                onClose={() => setSizeModal(false)}
              />
            )}
            <hr className="bg-dark sv-line" />
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle storageDropdown"
                href="#"
                id="storageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ref={storageDropdownRef}
              >
                <FormattedMessage id="header.self-storage" />{' '}
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="storageDropdown"
                data-bs-popper="static"
              >
                <li>
                  <Link
                    href={`/${router.locale}/self-storage`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.store-locator" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/self-storage/residential`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.residential" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/self-storage/car-storage`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.vehicles" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/self-storage/student-storage`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.student-storage" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/self-storage/business`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.business" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/self-storage/moving`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.moving" />
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <hr className="bg-dark sv-line" />
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="portableStorageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FormattedMessage id="header.portable-storage" />{' '}
              </a>

              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="portableStorageDropdown"
              >
                <li>
                  <Link
                    href={`/${router.locale}/portable-storage/residential`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.residential" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/portable-storage/business-storage`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.business" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/portable-storage/moving`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.moving" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={`/${router.locale}/portable-storage/long-distance-moving`}
                    prefetch={false}
                  >
                    <a className="dropdown-item close-menu">
                      <FormattedMessage id="header.long-dist-moving" />
                    </a>
                  </Link>
                </li>
              </ul>
            </li>
            <hr className="bg-dark sv-line" />
            <li className="nav-item">
              <Link href={PACKING_SUPPLIES_SHOP} prefetch={false}>
                <a className="nav-link close-menu" target="_blank">
                  <FormattedMessage id="header.packing-supplies" />
                  <OpenInNewIcon className="sv-menu-shop-portal-icon" />
                </a>
              </Link>
            </li>
            <hr className="bg-dark sv-line" />
            <li className="nav-item close-menu hidden-logo-xxs d-none">
              <Link href={`/${router.locale}/self-storage`} prefetch={false}>
                <a className="nav-link location">
                  <Image
                    src={locationPin}
                    alt="location-pin"
                    className="location-pin-img"
                    priority={true}
                  />
                  <FormattedMessage id="header.find-storage" />
                </a>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link close-menu">
                <div
                  onClick={() => setShowModal(true)}
                  className={styles.pointer}
                >
                  <FormattedMessage id="header.myaccount-makepayment" />
                </div>
              </a>
            </li>
            {showModal && (
              <MyAccountModal
                open={showModal}
                onClose={() => setShowModal(false)}
              />
            )}
            <hr className="bg-dark sv-line" />
            {[...locales].sort().map((llocale) =>
              locale !== llocale ? (
                <li key={llocale} className="nav-item close-menu">
                  <Link href={`${asPath}`} locale={llocale}>
                    <a className="nav-link">
                      {llocale === 'en' ? 'English' : 'Français'}
                    </a>
                  </Link>
                </li>
              ) : (
                ''
              )
            )}
          </ul>
        </div>
      </div>
    </nav>
    </>
  );
};

export default MobileHeader;
